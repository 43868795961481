// extracted by mini-css-extract-plugin
export var accentGradient = "ItemListWithImage-module--accent-gradient--930f3";
export var accentImage = "ItemListWithImage-module--accent-image--14ab2";
export var badge = "ItemListWithImage-module--badge--5a57c";
export var carrier = "ItemListWithImage-module--carrier--fe64f";
export var content = "ItemListWithImage-module--content--6d53a";
export var description = "ItemListWithImage-module--description--94601";
export var features = "ItemListWithImage-module--features--78e52";
export var image = "ItemListWithImage-module--image--ebbf6";
export var reverseAccentGradient = "ItemListWithImage-module--reverse-accent-gradient--a3eeb";
export var reverseAccentImage = "ItemListWithImage-module--reverse-accent-image--be1aa";
export var reverseContent = "ItemListWithImage-module--reverse-content--a8fd9";
export var reverseImage = "ItemListWithImage-module--reverse-image--a8857";
export var wrapper = "ItemListWithImage-module--wrapper--06145";