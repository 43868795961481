import clsx from 'clsx'
import React from 'react'
import PropTypes from 'prop-types'

const SvgArrowStraight = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      style={{ enableBackground: 'new 0 0 12 12' }}
      fill="none"
      className={clsx('fill-current', className)}
    >
      <path d="M9.129 5.0835L5.106 1.0605L6.1665 0L12 5.8335L6.1665 11.667L5.106 10.6065L9.129 6.5835H0V5.0835H9.129Z" />
    </svg>
  )
}
SvgArrowStraight.propTypes = {
  className: PropTypes.string,
}
export default SvgArrowStraight
