import React from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

// Component
import Seo from '@components/seo/Index'
import LayoutInnerPage from '@src/layouts/InnerPage'
import Banner from '@components/global/banner/Banner'
import Separator from '@components/pages/real-estate-websites/Separator'
import ItemListWithImage from '@components/pages/real-estate-websites/ItemListWithImage'
import ChooseWebsite from '@components/pages/real-estate-websites/ChooseWebsite'
import ProductQuizComparison from '@components/global/product/QuizComparison'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'

// CSS
import * as cx from './ProductLanding.module.scss'

const ProductParentTemplate = ({
  data: { page },
  pageContext: { seo, uri },
}) => {
  const productMetadata = page.productInputs

  // console.log(page)

  return (
    <LayoutInnerPage>
      <Seo title={page.title} seo={seo} uri={uri} />
      <Banner
        wrapperClassName={cx.banner}
        breadcrumbs={page?.seo?.breadcrumbs}
        title={page?.bannerForm?.title}
        description={page?.bannerForm?.description}
        button_text="Amplify your online presence"
        button_link="/"
        devices={{
          layout: 'PhoneTabletLaptopV2',
          mobile: `${page?.bannerForm?.mobile?.url}`,
          tablet: `${page?.bannerForm?.tablet?.url}`,
          laptop: `${page?.bannerForm?.laptop?.url}`,
        }}
      />
      <Separator
        title={productMetadata?.subheading[0]?.title}
        description={productMetadata?.subheading[0]?.description}
      />
      {productMetadata?.packages?.map((item, index) => {
        const isReverse = index % 2 ? true : false

        return (
          <div key={index}>
            <ItemListWithImage
              title={item.title}
              description={item.description}
              features={item.items}
              imgSrc={item?.image?.url}
              imgWidth={item?.image?.width}
              imgHeight={item?.image?.height}
              buttonText={item.button_text}
              buttonUrl={item.button_url}
              reverse={isReverse}
            />
          </div>
        )
      })}
      <ChooseWebsite items={productMetadata?.website} />
      <ProductQuizComparison />
      <LeadContactForm
        version={page?.leadContactForm?.version}
        background={page?.leadContactForm?.background}
        modelOrBackground={page?.leadContactForm?.model}
        title={page?.leadContactForm?.title}
        subtitle={page?.leadContactForm?.subtitle}
        submitLabel={page?.leadContactForm?.submit}
        leadName={page?.title}
        layout="default"
      />
    </LayoutInnerPage>
  )
}

export default ProductParentTemplate

export const pageQuery = graphql`
  query ProductParentPageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")

      seo {
        breadcrumbs {
          text
          url
        }
      }

      productInputs {
        subheading {
          title
          description
        }
        packages {
          title
          description
          button_text
          button_url
          items {
            check
            content
          }
          image {
            id
            url
            width
            height
            alt
          }
        }

        website {
          title
          description
          items {
            text
            url
          }
          image {
            id
            url
            width
            height
            alt
          }
        }
      }

      bannerForm {
        title
        description
        laptop {
          id
          url
          width
          height
          alt
        }
        tablet {
          id
          url
          width
          height
          alt
        }
        mobile {
          id
          url
          width
          height
          alt
        }
      }

      leadContactForm {
        background {
          url
          srcset
          sizes
          width
          height
        }
        version
        model
        title
        subtitle
        submit
      }
    }
  }
`
