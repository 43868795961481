import { useStaticQuery, graphql } from 'gatsby'

export const useHomePageMetadata = () => {
  const query = useStaticQuery(graphql`
    query homePageMetaData {
      wp {
        Banner: homeTopFold {
          logo {
            image {
              url
              width
              height
              alt
              srcset
              sizes
            }
          }
          devices {
            title
            gradient
            device_style
            description
            button_text
            device_1 {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
            device_2 {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
            device_3 {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
          }
          video {
            url
            description
            image {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
          }
        }

        AwardWinning: homeAwardWinning {
          background {
            url
          }
          title
          subtitle
          description
          button_text
          button_url
          logos {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
          laptop {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
          tablet_portrait {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
          tablet_landscape {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
        }

        OurWorks: homeOurWorks {
          background {
            url
          }
          title
          subtitle
          description
          button_text
          button_url
          devices {
            client_name
            client_domain
            client_url
            laptop {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
            tablet {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
            mobile {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
          }
        }

        PrestigiousCompany: homePrestigiousCompany {
          background {
            url
          }
          title
          subtitle
          description
          button_text
          button_url
          agents {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
          logos {
            url
            width
            height
            srcset
            alt
            sizes
          }
        }

        DigitalMarketing: homeDigitalMarketing {
          background {
            url
          }
          title
          subtitle
          description
          products {
            title
            description
            button_text
            button_url
            image {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
          }
        }

        Events: homeEvents {
          background {
            url
          }
          title
          subtitle
          description
          button_text
          button_url
          photos {
            url
          }
          logos {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
        }

        Partnerships: homePartnerships {
          title
          subtitle
          description
          button_text
          button_url
          laptop {
            url
          }
          tablet_large {
            url
          }
          tablet_small {
            url
          }
          zoom {
            url
          }
          logos {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
        }

        DesignComparison: homeDesignComparison {
          background {
            url
          }
          title
          subtitle
          products {
            title
            description
            items {
              check
              content
            }
            button_text
            button_url
            image {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
          }
        }

        Testimonials: homeTestimonials {
          title
          subtitle
          description
          button_text
          button_url
          items {
            image {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
            logo {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
            id
            client_name
            client_domain
            client_testimonial
            client_url
            video_url
          }
        }

        Social: homeSocial {
          title
          icon_title
        }

        QuizComparison: homeShortQuiz {
          title
          subtitle
          description
          button_text
          background {
            url
          }
        }
        TableComparison: homeTableComparison {
          title
          subtitle
          button_text
          button_url
          products {
            title
            button_text
            button_link
            items {
              check
              content
            }
            image {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
          }
        }
      }
    }
  `)

  return query
}
