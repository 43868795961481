import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { extractGatsbyImageDataRelativePath } from '@utils/imageFile'

import BgProduct from '/content/assets/real-estate-websites/product-bg.jpg'
import ChooseScale from '/content/assets/real-estate-websites/choose-scale.jpg'
import ChooseExpertise from '/content/assets/real-estate-websites/choose-expertise.jpg'
import SvgCheckedCircle from '@svg/checkedCircle.inline'
import SvgArrowStraight from '@svg/arrowStraight.inline'
import * as cx from './ChooseWebsite.module.scss'

const ChooseWebsite = ({ items }) => {
  return (
    <section
      className={cx.wrapper}
      style={{ backgroundImage: `url(${BgProduct})` }}
    >
      <div className={`container-stretch ${cx.content}`}>
        <div className={`row ${cx.subheading}`}>
          <div className="col">
            <h2 className="subtitle-7">
              Let's Get Started <SvgCheckedCircle />
            </h2>
          </div>
        </div>
        <div className={`row ${cx.items}`}>
          {items.map((item, index) => {
            return (
              <div
                key={index}
                className={`col ${index === 0 ? cx.scale : cx.expertise}`}
              >
                <h3 className="heading-3">{item.title}</h3>
                <p className="default-body">{item.description}</p>
                <div className={`image-responsive ${cx.image}`}>
                  <canvas width={523} height={384}></canvas>
                  <img
                    src={item.image.url}
                    alt={item.title}
                    width={item.image.width}
                    height={item.image.height}
                  />
                </div>
                <ul>
                  {item.items.map((childItem, childIndex) => {
                    return (
                      <li key={childIndex}>
                        <Link to={childItem.url} className="primary-button">
                          <span>{childItem.text}</span> <SvgArrowStraight />
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

ChooseWebsite.propTypes = {
  items: PropTypes.array,
}

export default ChooseWebsite
