import React from 'react'
import PropTypes from 'prop-types'

import CheckItems from '@components/global/CheckItems'
import BgProduct from '/content/assets/real-estate-websites/product-bg.jpg'
import BgProductAlt from '/content/assets/real-estate-websites/product-alt-bg.jpg'
import AccentAix from '/content/assets/real-estate-websites/accent-aix.jpg'
import AccentAp from '/content/assets/real-estate-websites/accent-ap.jpg'
import AccentSc from '/content/assets/real-estate-websites/accent-sc.jpg'
import AccentIs from '/content/assets/real-estate-websites/accent-is.jpg'
import SvgStar from '@src/svg/star.inline'
import * as cx from './ItemListWithImage.module.scss'

const ItemListWithImage = ({
  wrapperClassName = '',
  title,
  description,
  features,
  imgSrc,
  imgWidth,
  imgHeight,
  buttonText = 'Learn More',
  buttonUrl = '#',
  reverse = false,
}) => {
  const backgroundImages = {
    'Agent Image X': {
      wrapper: `${BgProduct}`,
      accent: `${AccentAix}`,
      gradient: 'bg-gradient-aix',
    },
    'Agent Pro': {
      wrapper: `${BgProductAlt}`,
      accent: `${AccentAp}`,
      gradient: 'bg-gradient-skys-the-limit',
    },
    'Semi-Custom': {
      wrapper: `${BgProduct}`,
      accent: `${AccentSc}`,
      gradient: 'bg-gradient-blue-surf',
    },
    'Imagine Studio': {
      wrapper: `${BgProductAlt}`,
      accent: `${AccentIs}`,
      gradient: 'bg-dark-blue',
    },
  }

  return (
    <section
      className={`${cx.wrapper} ${wrapperClassName}`}
      style={{ backgroundImage: `url(${backgroundImages[title]['wrapper']})` }}
      data-name={title.replaceAll(' ', '-').toLowerCase()}
    >
      <div className={`container-stretch ${cx.carrier}`}>
        <div className="row">
          <div
            className={`col item-list-image ${cx.image}  ${
              reverse ? cx.reverseImage : ''
            }`}
          >
            <canvas
              width={402}
              height={402}
              className={`${cx.accentGradient} ${
                backgroundImages[title]['gradient']
              } ${reverse ? cx.reverseAccentGradient : ''}`}
            ></canvas>
            <canvas
              width={402}
              height={402}
              className={`${cx.accentImage} ${
                reverse ? cx.reverseAccentImage : ''
              }`}
              style={{
                backgroundImage: `url(${backgroundImages[title]['accent']})`,
              }}
            ></canvas>
            <img src={imgSrc} width={imgWidth} height={imgHeight} alt={title} />
          </div>
          <div
            className={`col item-list-content ${cx.content} ${
              reverse ? cx.reverseContent : ''
            }`}
          >
            <h2 className="heading-2">{title}</h2>
            {title === 'Semi-Custom' && (
              <div className={`badge ${cx.badge}`}>
                Most Popular <SvgStar />
              </div>
            )}
            <div className={cx.description}>
              {/* <small className="small-heading">
                <strong>Best for</strong>
              </small> */}
              <p className="subtitle-5">{description}</p>
            </div>
            <div className={cx.features}>
              <small className="small-heading">
                <strong>Key features</strong>
              </small>
              <CheckItems
                items={features}
                itemClass="subtitle-5"
                hasLink={true}
                buttonClass="primary-button large"
                buttonText={buttonText}
                buttonUrl={buttonUrl}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

ItemListWithImage.propTypes = {
  wrapperClassName: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  features: PropTypes.array.isRequired,
  imgSrc: PropTypes.string.isRequired,
  imgWidth: PropTypes.number.isRequired,
  imgHeight: PropTypes.number.isRequired,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
  reverse: PropTypes.bool,
}

export default ItemListWithImage
