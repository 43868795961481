import React from 'react'

const SvgDivider = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 4"
      style={{ enableBackground: 'new 0 0 64 4' }}
      className="fill-current"
    >
      <path d="M2.50098 1.75L0.000976562 0.556624V3.44338L2.50098 2.25V1.75ZM61.2866 2.25L63.7866 3.44338V0.556624L61.2866 1.75V2.25ZM2.25098 2.25H61.5366V1.75H2.25098V2.25Z" />
    </svg>
  )
}

export default SvgDivider
