// extracted by mini-css-extract-plugin
export var carousel = "QuizComparison-module--carousel--75e10";
export var checklist = "QuizComparison-module--checklist--a69c1";
export var comparison = "QuizComparison-module--comparison--a70a6";
export var comparisonLink = "QuizComparison-module--comparison-link--947c5";
export var floating = "QuizComparison-module--floating--c1264";
export var itemLink = "QuizComparison-module--item-link--4c162";
export var progressBar = "QuizComparison-module--progress-bar--da674";
export var quiz = "QuizComparison-module--quiz--138c7";
export var quizBg = "QuizComparison-module--quiz-bg--22543";
export var quizContainer = "QuizComparison-module--quiz-container--d4ea8";
export var quizContent = "QuizComparison-module--quiz-content--e5d96";
export var section = "QuizComparison-module--section--ba1a7";
export var spacer = "QuizComparison-module--spacer--bc1e0";
export var subtitle = "QuizComparison-module--subtitle--fd2ed";
export var title = "QuizComparison-module--title--b18aa";