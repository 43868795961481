import React from 'react'

const SvgStar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10 9"
      style={{ enableBackground: 'new 0 0 10 9' }}
      className="fill-current"
      fill="none"
    >
      <path d="M7.81475 5.73942C7.70116 5.8495 7.64897 6.0087 7.67485 6.16483L8.06473 8.32258C8.09762 8.50546 8.02044 8.69054 7.86738 8.79624C7.71739 8.90588 7.51784 8.91903 7.35425 8.83132L5.41184 7.81823C5.3443 7.78227 5.2693 7.76297 5.19256 7.76078H5.0737C5.03248 7.76692 4.99213 7.78007 4.95529 7.80025L3.01244 8.81816C2.91639 8.86641 2.80763 8.88351 2.70106 8.86641C2.44142 8.81729 2.26819 8.56993 2.31073 8.30899L2.70106 6.15124C2.72693 5.99379 2.67474 5.83371 2.56115 5.72188L0.977486 4.18689C0.845039 4.05839 0.798989 3.86542 0.859512 3.69131C0.91828 3.51764 1.06827 3.39089 1.2494 3.36239L3.42908 3.04618C3.59486 3.02908 3.74046 2.92821 3.81502 2.77909L4.77548 0.809926C4.79828 0.766069 4.82767 0.725721 4.86319 0.691513L4.90266 0.660813C4.92327 0.638008 4.94696 0.619149 4.97327 0.6038L5.02108 0.586257L5.09563 0.555557H5.28027C5.44517 0.572661 5.59034 0.671339 5.66621 0.818698L6.63939 2.77909C6.70956 2.9225 6.84595 3.02206 7.0034 3.04618L9.18308 3.36239C9.36728 3.3887 9.52121 3.51589 9.58217 3.69131C9.63963 3.86718 9.59007 4.06015 9.45499 4.18689L7.81475 5.73942Z" />
    </svg>
  )
}

export default SvgStar
