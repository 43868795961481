import React, { useRef } from 'react'
import { Link } from 'gatsby'
import { useHomePageMetadata } from '@hooks/home-page-metadata'
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide'
import parse from 'html-react-parser'

import CheckItems from '@components/global/CheckItems'
import ElementImage from '@components/global/element/Image'
import SvgDivider from '@svg/divider.inline'
import * as cx from './QuizComparison.module.scss'

const ProductQuizComparison = () => {
  const slideRef = useRef(null)
  const homeShortQuiz = useHomePageMetadata().wp.QuizComparison
  const homeTableComparison = useHomePageMetadata().wp.TableComparison

  return (
    <>
      <section id="product-comparison" className={`${cx.section}`}>
        <div className={cx.quiz}>
          <div
            className={cx.quizBg}
            style={{
              backgroundImage: `url(${homeShortQuiz.background.url})`,
            }}
          ></div>
          <div className={cx.quizContainer}>
            <div className={cx.quizContent}>
              <h2 className={`heading-2`}>{parse(homeShortQuiz.title)}</h2>
              <p className={`${cx.subtitle} subtitle-1`}>
                {parse(homeShortQuiz.subtitle)}
              </p>
              <p className={`subtitle-5`}>{parse(homeShortQuiz.description)}</p>

              <form action="">
                <div className="form-row">
                  <div className="form-col">
                    <div className="form-control">
                      <label htmlFor="quiz-type">I am a</label>
                      <input
                        type="text"
                        id="quiz-type"
                        placeholder="type or select"
                      />
                      <span></span>
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-col">
                    <div className="form-control">
                      <label htmlFor="quiz-looking">Looking for a</label>
                      <input
                        type="text"
                        id="quiz-looking"
                        placeholder="type or select"
                      />
                      <span></span>
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-col">
                    <div className="form-control form-submit">
                      <button type="submit" className="primary-button large">
                        {homeShortQuiz.button_text}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className={cx.floating}>
          <h2>
            <span className="subtitle-1">{homeTableComparison.title}</span>
            <span className={`spacer ${cx.spacer}`}>
              <SvgDivider />
            </span>
            <span className="heading-2">{homeTableComparison.subtitle}</span>
          </h2>
        </div>

        <div className={cx.comparison}>
          <Splide
            ref={slideRef}
            hasTrack={false}
            tag="div"
            options={{
              arrows: false,
              arrowPath: false,
              pagination: false,
              perPage: 4,
              perMove: 1,
              autoHeight: false,
              lazyLoad: 'sequential',
              drag: false,
              autoWidth: true,
              breakpoints: {
                1199: {
                  drag: true,
                  perPage: 2,
                },
                575: {
                  dragMinThreshold: 5,
                  drag: 'free',
                  perPage: 1,
                },
              },
            }}
            className={`${cx.carousel}`}
          >
            <SplideTrack>
              {homeTableComparison.products.map((item, index) => {
                return (
                  <SplideSlide key={index}>
                    <ElementImage
                      src={item?.image?.url}
                      width={item?.image?.width}
                      height={item?.image?.height}
                      alt={item.image.alt}
                      srcSet={item?.image?.srcset}
                      sizes={item?.image?.sizes}
                      splideLazyload={false}
                    />
                    <span className={`${cx.title} subtitle-4`}>
                      {item.title}
                    </span>
                    <CheckItems
                      items={item.items}
                      parentClass={cx.checklist}
                      hasLink={true}
                      buttonClass={`primary-button ${cx.itemLink}`}
                      buttonText="Tell me more"
                      buttonUrl="/"
                    />
                  </SplideSlide>
                )
              })}
            </SplideTrack>
          </Splide>

          <div className={cx.comparisonLink}>
            <Link
              to={homeTableComparison.button_url}
              className={`primary-button alt large`}
            >
              <span>{homeTableComparison.button_text}</span>
            </Link>
          </div>
        </div>
      </section>
    </>
  )
}

export default ProductQuizComparison
